/* W-Note-Header View */

.remirror-editor w-note-header {
  display: block;
  text-align: unset;
  &:empty::after {
    content: '*';
    white-space: nowrap;
    //color: #165996;
  }

  &::after {
    content: '';
    white-space: nowrap;
  }

  &:empty::before {
    content: '';
    white-space: nowrap;
  }
}
