/* WEML <br/> styles */

.remirror-editor br {
  content: ' ' !important;
}

.remirror-editor br::before {
  /*content: '\00B6\000A';*/
  content:"↵\a";
  white-space: pre;
  padding-left: 1px;
  color: var(--rmr-color-active-primary);
}

.remirror-editor br:empty::after {
  content: '';
  /*content:"↵\a";*/
  white-space: normal;
  color: var(--rmr-color-active-primary);
}

.remirror-editor .remirror-is-empty br::before,
.remirror-editor .remirror-is-empty br::before {
  content: '';
  white-space: pre;
  padding-left: 1px;
  color: transparent;
}
