
.remirror-editor.ProseMirror {
  figure {
    figcaption {
      w-text-block:empty::before{
        content: '*';
        white-space: nowrap;
        color: #aaa;
      }
      &.remirror-decoration {
        cursor: pointer;
        &::before {
          left: 0;
          right: 0;
          color: #aaa;
          font-style: italic;
          content: attr(data-placeholder);
        }
      }
    }

  }
}