body {
  --reset-all-cursors--cursor: 'auto';
}
.reset-all-cursors
{
  cursor: var(--reset-all-cursors--cursor) !important;
}

.reset-all-cursors *
{
  cursor: inherit !important;
}
