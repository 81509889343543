/* W-Note-Body View */

.w-note-body-node-view-wrapper {
  width: 100%;
}

.remirror-editor w-note {
  &::before {
    display: inline-flex;
    content: '\00a0\00a0\00a0\00a0'attr(data-header);
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: anywhere;
    vertical-align: 20%;
    line-height: normal;
    cursor: pointer;
  }
}

.remirror-editor w-note-body {
  display: block;
}

.remirror-editor w-note-body w-text-block:empty::after {
  content: '*';
  white-space: nowrap;
  color: #165996;
}

.remirror-editor w-note-body::after {
  content: '';
  white-space: nowrap;
}

.remirror-editor w-note-body::before {
  content: '';
  white-space: nowrap;
}
