.app-filter-form {
  margin-bottom: 15px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .app-filter-form--item-field {
    &:first-child{
      min-width: 200px;
      max-width: 300px;
    }
  }

  .app-filter-form--item-field .ant-select-selection-overflow {
    max-height: calc(1.5715rem * 3 + 10px);
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

