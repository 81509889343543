/* W-Page Node View */

$weml-w-page-before-background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z'/%3E%3C/g%3E%3C/svg%3E");
$weml-w-page-background-size: auto;

.remirror-editor w-page::before {
  content: '\00a0\00a0\00a0\00a0';
  color: transparent;
  text-indent: 0;
  white-space: nowrap;
  background-image: $weml-w-page-before-background-image;
  background-size: $weml-w-page-background-size;
  background-repeat: no-repeat;
  background-position: center;
  padding-right: 5px;
}
