.dnd-sortable-tree_item_wrapper {
  list-style: none;
  box-sizing: border-box;
  margin-bottom: -1px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 24px;
}

.dnd-sortable-tree_item_wrapper_content{
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 0;
  box-sizing: border-box;
  width: 100%;
}

.dnd-sortable-tree_item_wrapper_clone {
  //display: inline-block;
  pointer-events: none;
  padding: 5px;
}

//.dnd-sortable-tree_item_wrapper_clone > .dnd-sortable-tree_item_wrapper_line_wrapper {
//  display: none;
//}

.dnd-sortable-tree_item_wrapper_clone > .dnd-sortable-tree_item_wrapper_tree-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 24px;
  border-radius: 4px;
}

.dnd-sortable-tree_item_wrapper_ghost {
  opacity: 0.5;
}

.dnd-sortable-tree_item_wrapper_disable-selection {
  user-select: none;
  -webkit-user-select: none;
}

.dnd-sortable-tree_item_wrapper_disable-interaction {
  pointer-events: none;
}

.dnd-sortable-tree_item_wrapper_line-wrapper {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.dnd-sortable-tree_item_wrapper_line {
  all: unset;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    inset-inline-end: 12px;
    bottom: -1px;
    border-inline-end: 0.5px solid #424242;
    content: "";
  }
}


.dnd-sortable-tree_item_wrapper_line-last {
  all: unset;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;

  //&::before {
  //    position: absolute;
  //    top: 0;
  //    inset-inline-end: 12px;
  //    bottom: -1px;
  //    border-inline-end: 1px solid #424242;
  //    content: "";
  //}
}

.dnd-sortable-tree_item_wrapper_line-to_self {
  all: unset;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    inset-inline-end: 12px;
    bottom: -1px;
    margin-inline-start: -1px;
    border-inline-end: 1px solid #424242;
    content: "";
  }
  &::after {
    position: absolute;
    width: 10px;
    height: 12px;
    border-bottom: 1px solid #424242;
    content: "";
  }
}


.dnd-sortable-tree_item_wrapper_line-to_self-last {
  all: unset;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0;
    inset-inline-end: 12px;
    bottom: 12px;
    margin-inline-start: 12px;
    border-inline-end: 1px solid #424242;
    content: "";
  }
  &::after {
    position: absolute;
    width: 10px;
    height: 12px;
    border-bottom: 1px solid #424242;
    content: "";
  }
}

.dnd-sortable-tree_item_wrapper_handle {
  width: 20px;
  align-self: stretch;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='12'><path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path></svg>")
  no-repeat center;
}