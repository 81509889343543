//.react-resizable {
//  position: relative;
//  background-clip: padding-box;
//}
//
//.react-resizable-handle {
//  position: absolute;
//  right: -1px;
//  bottom: 0;
//  z-index: 1;
//  width: 5px;
//  height: 100%;
//  cursor: col-resize !important;
//}

.managerHeader--breadcrumb > span {
    display: block;
    white-space: nowrap !important;
    margin: 0 4px;
    overflow: hidden;
  }


.foldersBreadcrumb {
  display: grid;
  white-space: nowrap !important;

  ol {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    li {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }
}
